

<template>
<div>
    <vs-popup title="Connexion" :active.sync="isPopupActiveLocal">

      <div class="flex flex-row justify-center items-center space-x-3">
          <a @click="AuthProvider('facebook')" title="se connecter avec facebook" class="w-11 h-11 items-center justify-center inline-flex rounded-2xl font-bold text-lg bg-blue-900 hover:shadow-lg cursor-pointer transition ease-in duration-300">
              <img class="w-4 h-4" src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHhtbG5zOnN2Z2pzPSJodHRwOi8vc3ZnanMuY29tL3N2Z2pzIiB3aWR0aD0iNTEyIiBoZWlnaHQ9IjUxMiIgeD0iMCIgeT0iMCIgdmlld0JveD0iMCAwIDI0IDI0IiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCA1MTIgNTEyIiB4bWw6c3BhY2U9InByZXNlcnZlIiBjbGFzcz0iIj48Zz48cGF0aCB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGQ9Im0xNS45OTcgMy45ODVoMi4xOTF2LTMuODE2Yy0uMzc4LS4wNTItMS42NzgtLjE2OS0zLjE5Mi0uMTY5LTMuMTU5IDAtNS4zMjMgMS45ODctNS4zMjMgNS42Mzl2My4zNjFoLTMuNDg2djQuMjY2aDMuNDg2djEwLjczNGg0LjI3NHYtMTAuNzMzaDMuMzQ1bC41MzEtNC4yNjZoLTMuODc3di0yLjkzOWMuMDAxLTEuMjMzLjMzMy0yLjA3NyAyLjA1MS0yLjA3N3oiIGZpbGw9IiNmZmZmZmYiIGRhdGEtb3JpZ2luYWw9IiMwMDAwMDAiIHN0eWxlPSIiIGNsYXNzPSIiPjwvcGF0aD48L2c+PC9zdmc+">
          </a>
          <a @click="AuthProvider('google')" title="se connecter avec google" class="w-11 h-11 items-center justify-center inline-flex rounded-2xl font-bold text-lg shadow-md hover:bg-gray-10 hover:shadow-lg cursor-pointer transition ease-in duration-300">
              <svg class="h-6 w-6" viewBox="0 0 40 40">
                  <path d="M36.3425 16.7358H35V16.6667H20V23.3333H29.4192C28.045 27.2142 24.3525 30 20 30C14.4775 30 10 25.5225 10 20C10 14.4775 14.4775 9.99999 20 9.99999C22.5492 9.99999 24.8683 10.9617 26.6342 12.5325L31.3483 7.81833C28.3717 5.04416 24.39 3.33333 20 3.33333C10.7958 3.33333 3.33335 10.7958 3.33335 20C3.33335 29.2042 10.7958 36.6667 20 36.6667C29.2042 36.6667 36.6667 29.2042 36.6667 20C36.6667 18.8825 36.5517 17.7917 36.3425 16.7358Z" fill="#FFC107"/>
                  <path d="M5.25497 12.2425L10.7308 16.2583C12.2125 12.59 15.8008 9.99999 20 9.99999C22.5491 9.99999 24.8683 10.9617 26.6341 12.5325L31.3483 7.81833C28.3716 5.04416 24.39 3.33333 20 3.33333C13.5983 3.33333 8.04663 6.94749 5.25497 12.2425Z" fill="#FF3D00"/>
                  <path d="M20 36.6667C24.305 36.6667 28.2167 35.0192 31.1742 32.34L26.0159 27.975C24.3425 29.2425 22.2625 30 20 30C15.665 30 11.9842 27.2359 10.5975 23.3784L5.16254 27.5659C7.92087 32.9634 13.5225 36.6667 20 36.6667Z" fill="#4CAF50"/>
                  <path d="M36.3425 16.7358H35V16.6667H20V23.3333H29.4192C28.7592 25.1975 27.56 26.805 26.0133 27.9758C26.0142 27.975 26.015 27.975 26.0158 27.9742L31.1742 32.3392C30.8092 32.6708 36.6667 28.3333 36.6667 20C36.6667 18.8825 36.5517 17.7917 36.3425 16.7358Z" fill="#1976D2"/>
              </svg>
          </a>
      </div>

      <div class="flex items-center justify-center mt-4 mb-4 space-x-2">
          <span class="h-px w-16 bg-gray-200"></span>
          <span class="text-gray-300 font-normal">Ou continuer avec</span>
          <span class="h-px w-16 bg-gray-200"></span>
      </div>

      <div class="flex -mx-3">
          <div class="w-full px-3">
              <label for="" class="text-xs font-semibold px-1">{{ $t('Email') }}
                  <span class="text-red-500" v-if="customerSettingsListFields.includes('email')">*</span>
              </label>
              <div class="flex">
                  <div class="w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center"><i class="mdi mdi-email-outline text-gray-400 text-lg"></i></div>
                  <input
                      class="w-full -ml-10 pl-10 pr-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-indigo-500"
                      name="email"
                      type="email"
                      placeholder="votre@email.fr"
                      v-model="email"
                      v-validate="{ required: customerSettingsListFields.includes('email'), email: true }"
                      :data-vv-as="$t('Email')"
                  >
              </div>
              <span class="text-danger text-sm">{{ errors.first('email') }}</span>
          </div>
      </div>

      <div class="flex mt-3 -mx-3">
          <div class="w-full px-3">
              <label for="" class="text-xs font-semibold px-1">{{ $t('Password') }}
                  <span class="text-red-500">*</span>
              </label>
              <div class="flex">
                  <div class="w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center"><i class="mdi mdi-lock-outline text-gray-400 text-lg"></i></div>
                  <input
                      class="w-full -ml-10 pl-10 pr-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-indigo-500"
                      type="password"
                      autocomplete="new-password"
                      placeholder="************"
                      name="password"
                      v-model="password"
                      v-validate="'required'"
                      :data-vv-as="$t('Password')"
                  >
              </div>
          </div>
      </div>

      <vs-button 
          class="w-full flex justify-center rounded-full mt-4 p-3 tracking-wide font-semibold shadow-lg cursor-pointer transition ease-in duration-500"
          ref="loadableButton"
          @click="loginClient"
          :disabled="!validateFormLogin"
      >
        {{ $t('Login') }}
      
        <svg xmlns="http://www.w3.org/2000/svg" class="inline h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 16l-4-4m0 0l4-4m-4 4h14m-5 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h7a3 3 0 013 3v1" />
        </svg>
      </vs-button>

    </vs-popup>
</div>
</template>

<script>

export default {
  props: {
    customerSettingsListFields: {
      required: true
    },
    isPopupActive: {
      required: true
    },
  },
  data () {
    return {
      popupBackgroundColor:"#f8f8f8",
      quantityRequested:1,
      quantityRequestedOption:0,
      productOptionsList:null,
      changeDates: false,
      selectedStartEndDateTime: null,

      email: '',
      password: '',
    }
  },
  watch: {
    isPopupActiveLocal (newValue, oldValue) {

      console.log('isPopupActiveLocal newValue', newValue)
      console.log('isPopupActiveLocal oldValue', oldValue)
      this.email = ''
      this.password = ''
    }
  },
  components: {
  },
  computed: {
    validateFormLogin () {
      return this.email !== '' && this.password !== ''
    },
    isPopupActiveLocal: {
      get () {
        return this.isPopupActive
      },
      set (val) {
        if (!val) {
          this.$emit('closeSidebar')
        }
      }

    },
  },
  methods: {

    checkLogin () {
      // If user is already logged in notify
      if (this.$store.state.auth.isUserLoggedIn()) {

        // Close animation if passed as payload
        // this.$vs.loading.close()

        this.$vs.notify({
          title: 'Tentative de connexion',
          text: 'Vous êtes déjà connecté!',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning'
        })

        return false
      }
      return true
    },


    AuthProvider(provider) {
      this.$vs.loading()
      
      var self = this

      this.$auth.authenticate(provider).then(response => {

      self.SocialLogin(provider,response)

      }).catch(err => {
          this.$vs.loading.close()
          this.$vs.loading.close('#popup-login > .con-vs-loading')
          console.log({err:err})
      })
    },

    SocialLogin(provider, response) {
      response.provider = provider
      response.config = this.$auth.options.providers[provider].redirectUri;
      const payload = {
        provider: provider,
        response: response,
        notify: this.$vs.notify,
        loading: this.$vs.loading
      }

      console.log('payload SocialLogin', payload)

      this.$store.dispatch('auth/SocialLogin', payload)
    },
    
    async loginClient () {
      // If form is not validated or user is already login return
      if (!this.validateFormLogin || !this.checkLogin()) return

      this.$vs.loading()

      const payload = {
        userDetails: {
          email: this.email,
          password: this.password
        },
        notify: this.$vs.notify,
        loading: this.$vs.loading
      }
      
      let response = await this.$store.dispatch('auth/loginClient', payload)

      if(response.status == 200) {
        this.$emit('closeSidebar')
      }
      
    },

    
  },
	
}

</script>

<style lang="css" scoped>

</style>

    